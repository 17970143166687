/*
 * 업무구분   : 업무공통
 * 화면 명    : MSPBC010M
 * 화면 설명  : 문의사항 보내기
 */
 <template>
  <ur-page-container class="msp" type="subpage" :show-title="true" title="문의사항 보내기" :topButton="true"><!-- type-popup -->

    <!-- Content영역 -->    
    <ur-box-container ref="scrollEl" direction="column" alignV="start" class="msp-bc-wrap overflow-scroll-y" style="height: 600px;">      
      <ur-box-container alignV="start" componentid="" direction="row" class="con-area pb132">
        <span class="title fs18rem fwm mt30">사용 중 궁금한 점을 보내주세요</span>        
        <span class="txt mt10">
            <span class="txt-ment">자주묻는 질문을 챗봇에서 빠르게 확인해보세요<br/></span>
            (<span @click="fn_ChatBotContent" style="color:#416dff;">챗봇 별리링크</span>)<br/><br/>
            <span class="txt-ment">서비스 문의 02-3114-500<br/>
              ({{lv_OperTime ? lv_OperTime : ''}})<br/><br/>
              보내주신 문의는 담당자 검토 후 답변드리겠습니다. 일부 문의는 휴일 이후 영업일에 답변될 수 있습니다.
            </span>
            
        </span>
        <div class="w100">
          <div class="mt50 w100">
            <span class="contain-title ">성명</span>
            <mo-text-field v-model.trim="userInfo.userNm" readonly class="form-input-name full mt10 input-line-black"  underline placeholder="이름" />
          </div>
          <div class="mt50 w100">
            <span class="contain-title ">소속</span>
            <mo-text-field v-model.trim="userInfo.userDeptNm" readonly class="form-input-name full mt10 input-line-black"  underline placeholder="소속" />
          </div>
          <div class="mt50 w100">
            <span class="contain-title ">전화번호</span>
            <mo-text-field v-model.trim="lv_userPhone" readonly class="form-input-name full mt10 input-line-black"  underline placeholder="전화번호" />
          </div>
          <div class="mt50 w100">
            <span class="contain-title ">관련메뉴</span>
            <ur-box-container alignV="start" componentid="" direction="row">
              <msp-bottom-select ref="bottomSelect" :items="lv_UppMenuList" v-model="lv_SelUppMenu.value" @input="fn_SelUppMenu" underline class="ns-dropdown-sheet w110px mt0 mr10" bottom-title="메뉴구분"/>
              <msp-bottom-select ref="bottomSelect" :items="lv_DtlMenuList" v-model="lv_SelDtlMenu.value" @input="fn_SelDtlMenu" underline class="ns-dropdown-sheet w110px mb0 flex-1" bottom-title="세부메뉴"/>              
            </ur-box-container>
          </div>
        </div>        
        <span class="title must circle fs18rem fwm mt50">문의사항</span>        
        <ur-box-container alignV="start" componentid="" direction="column" class="ns-segment">            
            <mo-text-area class="w150 hauto pa12 crTy-bk1" style="width:100%" cols="100" rows="10" v-model="lv_reqText" placeholder="문의사항을 입력해주세요." :maxlength="500" />
        </ur-box-container>
      </ur-box-container>
    </ur-box-container>

    <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column" class="ns-btn-relative">
      <div class="relative-div">
        <mo-button componentid="mo_button_002" color="primary" shape="border" size="medium" class="ns-btn-round white" @click="fn_Close()">취소</mo-button>
        <mo-button componentid="mo_button_002" color="primary" shape="border" size="medium" class="ns-btn-round blue" @click="fn_SendHolyDayReq()">발송</mo-button>        
      </div>
    </ur-box-container>

  </ur-page-container>
</template>
<script>
import moment from 'moment'
/***********************************************************************************
 * 공통 라이브러리 INCLUDE 영역	                                                   *
 ***********************************************************************************/
export default {
  /***********************************************************************************
   * Vue 파일 속성 정의 영역	                                                       *
   ***********************************************************************************/
  name: 'MSPBC010M',
  screenId: 'MSPBC010M',
  components: {},
  /***********************************************************************************
   * Life Cycle 함수 정의 영역	                                                     *
   ***********************************************************************************/
  created() {
    this.userInfo = this.getStore('userInfo').getters.getUserInfo    
    this.fn_SelFcInfo()
    this.fn_SelListMenuForMSP()
    this.fn_Init()
    this.getWkEvtHandler().$on(Msg.DEVICE.BACK_KEY, this.fn_ClickHeaderLeftClick) // backKey event 등록        
  },
  mounted() {},
  beforeDestroy () {    
    this.getWkEvtHandler().$off(Msg.DEVICE.BACK_KEY, this.fn_ClickHeaderLeftClick)
  },
  /***********************************************************************************
   * 화면변수 선언 영역	                                                             *
   ***********************************************************************************/
  props: {    
  },
  data() {
    return {
      lv_chatroomId: '',
      lv_targetChatroomId: '170021537001309184', // 개발계
      lv_channel: 'web', // 웹메신저:web, 녹스:knox
      lv_id: 'sarangon', // 사랑On 시스템 요청      
      lv_userPhone: '',
      lv_reqText: '',      
      lv_MenuList: [], // 화면에 노출할 메뉴리스트(1, 2레벨),
      lv_UppMenuList: [], // 화면에 노출할 메뉴리스트(1, 2레벨),
      lv_DtlMenuList: [], // 화면에 노출할 메뉴리스트(1, 2레벨),
      lv_SelUppMenu: '', // 대메뉴는 value 가 menuId
      lv_SelDtlMenu: '', // 중메뉴는 value 가 srnId
      lv_OperTime: '', // 운영시간
      lv_ChatIdList: {}, // 챗팅방ID목록
      userInfo: {}
    }
  },
  /***********************************************************************************
   * Computed 함수 정의 영역                                                         *
   ***********************************************************************************/
  computed: {    
  },
  /***********************************************************************************
   * watch 함수 정의 영역                                                         *
   ***********************************************************************************/
  watch: {    
  },
  /***********************************************************************************
   * 사용자 함수 정의 영역                                                           *
   ***********************************************************************************/
  methods: {
    /*********************************************************
     * Function명: fn_Init
     * 설명: 초기 config 설정
     *********************************************************/
    async fn_Init() {            
      try {
        let srvcQustTime = await this.$commonUtil.selMgrCfg(this, {envId: 'SRVC_QUST_TIME'}) // 메뉴신규라벨목록
        if (srvcQustTime?.length) {
          this.lv_OperTime = srvcQustTime[0].smoUnitl
          console.log('srvcQustTime > ', srvcQustTime)
        }

        let trnsChatIdList = await this.$commonUtil.selMgrCfg(this, {envId: 'TRNS_CHAT_ID_LIST'}) // 메뉴신규라벨목록
        if (trnsChatIdList?.length) {
          trnsChatIdList.forEach(item => {
            if (item?.envCntnt && item?.smoUnitl) {
              let menuId = item.smoUnitl.split('|')
              console.log('trnsChatIdList - item / menuId', item, menuId)
              if (menuId?.length) {
                this.lv_ChatIdList[menuId[0]] = item.envCntnt
                console.log('this.lv_ChatIdList', this.lv_ChatIdList)
              }
            }
          })          
          console.log('trnsChatIdList > ', trnsChatIdList)
        }
      } catch (e) {
        console.log('MSPBC010M, fn_Init - error >', e)
      }
    },
    /*********************************************************
     * Function명: fn_SendHolyDayReq
     * 설명: 휴일 문의사항 보내기
     *********************************************************/
    fn_SendHolyDayReq() {
      console.log('fn_SendHolyDayReq >')      
      // 문의사항 validation
      if (this.$bizUtil.isEmpty(this.lv_reqText)) {
        this.$addSnackbar('문의사항을 입력해주세요.')
        return
      }

      // 채팅방id validation
      let tgtChatId = ''
      if (this.lv_ChatIdList) {
        tgtChatId = this.lv_ChatIdList[this.lv_SelUppMenu.value]
        if (this.$bizUtil.isEmpty(tgtChatId)) {
          this.$addSnackbar(this.lv_SelUppMenu.text +' 채팅방 ID가 없습니다.')
          return
        }
      } else {
        this.$addSnackbar(this.lv_SelUppMenu.text +' 채팅방 ID가 없습니다.')
      }

      this.userInfo = this.getStore('userInfo').getters.getUserInfo      
      const pParams = {
        requestId: moment(new Date()).format('YYYYMMDDHHmmssms'),
        requestType: JSON.stringify(this.lv_SelDtlMenu), // 화면 ID 변경 필요
        targetChatroomId: tgtChatId,
        channel: this.lv_channel,
        id: this.lv_id,
        userName: this.userInfo.userNm,
        userDept: this.userInfo.userDeptNm,
        userPhone: this.$bizUtil.replaceAll(this.lv_userPhone, '-', ''),
        reqText: this.lv_reqText
      }
      console.log('pParams:', pParams)
      const trnstId = 'txTSSBC99S2'
      const errMsg = '재시도 후 동일 오류시 IT서비스데스크(02-3114-500)로 연락바랍니다.'
      return this.post(this, pParams, trnstId, 'S').then(response => {        
        if (response?.body) {
          const data = response.body
          console.log('fn_SendHolyDayReq', data)
          let msg = ''
          if (data?.result) {            
            msg = '문의사항이 등록되었습니다.'
            this.fn_Close()
          } else {
            msg = errMsg + '(오류코드:' + data.resultCode + ')'
          }
          this.getStore('confirm').dispatch('ADD', msg)
        } else {
          if (response.msgComm.msgCd === "EFWS004") { // 쿼리 실행 도중 오류 치환
            this.getStore('confirm').dispatch('ADD', errMsg)
          } else {
            this.getStore('confirm').dispatch('ADD', response.msgComm.msgDesc)
          }
        }
      }).catch(function (error) {
        window.vue.getStore('confirm').dispatch('ADD', errMsg)
        window.vue.error(error)
      })
    },
    
    /******************************************************************************
    * Function명 : fn_SelFcInfo
    * 설명       : FC정보조회
    *            : FC전화번호는 getStore('userInfo')에 가지고 있지 않음
    ******************************************************************************/
    fn_SelFcInfo () {
      let lv_Vm = this
      let pParams = {userEno: this.getStore('userInfo').getters.getUserInfo.userId}
      const trnstId = 'txTSSBC20S3'
      const auth = 'S'
      this.post(lv_Vm, pParams, trnstId, auth)
        .then( response => {
          if (response?.body) {
            const data = response.body
            this.lv_userPhone = this.$bizUtil.telNoWithHyphen(data.celno.trim())
          } else {
            this.getStore('confirm').dispatch('ADD', response.msgComm.msgDesc)
          }
        })
        .catch(function (error) {
          window.vue.error(error)
        })
    },

    /******************************************************************************
    * Function명  : fn_SelListMenuForMSP ()
    * 설명        : 사랑온 미니 전체메뉴 조회(권한역발별 화면 권한적용)
    * Params      : N/A
    * Return      : 사랑온 미니 전체메뉴
    ******************************************************************************/
    fn_SelListMenuForMSP () {
      const trnstId = 'txTSSBC04SA'
      const auth = 'S'      
      this.post(this, {}, trnstId, auth, {}, true).then(response => {        
        const data = response.body        
        this.lv_MenuList = []
        this.lv_UppMenuList = []        
        if (response?.body?.menuList?.length) {

          // 전체메뉴 중 화면에 노출 될 메뉴만 추출
          // 1. 관리자에서 0, 1, 2레벨의 3단계로 구성되어 있지만 화면에서는 1,2레벨로만 구성함
          // 2. 2레벨 메뉴를 가지고 있지 않은 1 레벨 메뉴들은 제외
          data.menuList.forEach(lv0 => {
            if ( lv0.menu?.length ) {
              lv0.menu.forEach(lv1 => {
                if ( lv1.menu?.length ) {                  
                  this.lv_MenuList.push(lv1)
                  this.lv_UppMenuList.push({value: lv1.menuId, text: lv1.label})
                }
              })
            }
          })
          console.log('this.lv_UppMenuList >', this.lv_UppMenuList)

          // 대메뉴 설정
          if (this.lv_UppMenuList?.length) {            
            this.lv_SelUppMenu = {...this.lv_UppMenuList[0]}

            console.log('this.lv_SelUppMenuVal >', this.lv_SelUppMenu)
            this.fn_SelUppMenu(this.lv_SelUppMenu.value)
          }
        }        
      })
      .catch(function (error) {
        window.vue.error(error)
      })
    },

    /******************************************************************************
    * Function명  : fn_SelUppMenu
    * 설명        : 메뉴구분 선택
    * Params      : input value
    * Return      : N/A
    ******************************************************************************/
    fn_SelUppMenu(val) {
      console.log('fn_SelUppMenu >', val)      
      const filteredMenuList = this.lv_MenuList.filter(item => item.menuId === val)[0]
      this.lv_SelUppMenu = {text: filteredMenuList.label, value: val}
      if (filteredMenuList?.menu?.length) {
        this.lv_DtlMenuList = []
        filteredMenuList.menu.forEach(item => {
          this.lv_DtlMenuList.push({value: item.srnId, text: item.label})
        })

        if (this.lv_DtlMenuList?.length) {
          this.lv_SelDtlMenu = {...this.lv_DtlMenuList[0]}
        }
        console.log('lv_SelDtlMenu >',  this.lv_SelDtlMenu)
      }
    },

    /******************************************************************************
    * Function명  : fn_SelDtlMenu
    * 설명        : 상세메뉴구분 선택
    * Params      : input value
    * Return      : N/A
    ******************************************************************************/
    fn_SelDtlMenu(val) {
      this.lv_SelDtlMenu = {...this.lv_DtlMenuList.filter(item => item.value === val)[0]}
      console.log('fn_SelDtlMenu >', val, this.lv_SelDtlMenu)
    },

    /************************************************************************************************
     * Function명  : fn_Close
     * 설명        : 팝업닫기
     ************************************************************************************************/
    fn_Close: function () {
      this.$router.go(-1)
    },

    /******************************************************************************
    * Function명 : fn_ChatBotContent
    * 설명       : 챗봇 링크 브라우저 호출
    *              (accessId : 접속시스템구분값(미니:Mini)/ userId : 로그인한 FC 사번)
    ******************************************************************************/
    fn_ChatBotContent () {
      console.log('fn_ChatBotContent>')
      let lv_Url = ''
      // 20220706 [ASR220600878] 사랑On 미니챗봇 스크립트 변경
      // 검증계 https://slimsg.samsunglife.com/messenger/?botId=BOT00000435&reload=N&isWeb=true&selFlag=PC
      // 운영계 https://slimsg.samsunglife.com/messenger/redirect?botId=BOT00000435&accessId=Mini&userId=사번
      if (process.env.NODE_ENV === 'local' || process.env.NODE_ENV === 'development') {
         lv_Url = 'https://ap1.t.slime2.samsunglife.kr:8449/messenger/fcBot?accessId=Mini&userId=' + this.getStore('userInfo').getters.getUserInfo.userId
      } else if (process.env.NODE_ENV === 'stage' || process.env.NODE_ENV === 'stage2') {        
        lv_Url = 'https://slimsg.samsunglife.com/messenger/redirect?botId=BOT00000435&reload=N&isWeb=true&selFlag=PC&accessId=Mini&userId=' + this.getStore('userInfo').getters.getUserInfo.userId
      } else {        
        lv_Url = 'https://slimsg.samsunglife.com/messenger/redirect?botId=BOT00000435&reload=N&isWeb=true&selFlag=PC&accessId=Mini&userId=' + this.getStore('userInfo').getters.getUserInfo.userId
      }

      if (this.$commonUtil.isMobile()) { // 모바일에서 작동
        window.fdpbridge.exec('intentBrowserPlugin', {uri: lv_Url}, () => {}, () => {})
      } else { // PC에서 작동
        window.open(lv_Url, '_blank')
      }
    },
  }
};
</script>
<style scoped>
</style>