/*
 * 업무구분: 휴일문의 조회
 * 화 면 명: MSPBC009M
 * 화면설명: 휴일문의 조회
 * 접근권한: 모든사용자
 * 작 성 일: 2023.05.23
 * 작 성 자: 송진의
 */
<template>
  <ur-page-container class="msp" title="휴일문의 조회" :show-title="true" type="subpage" :action-type="actionSlot" :topButton="true" @on-scroll-bottom="fn_GetService(false)"> <!-- 2023.02.07 수정 //-->
    <!-- 고정영역 -->
    <template #frame-header-fixed>
      <ur-box-container alignV="start"  direction="column" class="msp-pm-wrap">
        <ur-box-container alignV="start" componentid="" direction="column" class="ns-check-sum">
          <mo-list-item>
            <div class="list-item__contents">
              <div class="list-item__contents__title fexTy3">
                <span class="sum">총 <strong class="crTy-blue3">{{totalCount}}</strong> 건</span>
                <span class="ns-tool-tip mt7">                  
                  <mo-icon id="location0" icon="msp-tool-tip"></mo-icon>
                  <mo-tooltip target-id="location0" class="tool-tip-box right">
                    <ul class="terms-list-area">
                      <li>자주묻는 질문을 챗봇에서 빠르게 확인해보세요</li>                      
                      <li @click="fn_ChatBotContent">(<span style="color:#416dff;">챗봇 별리링크</span>)</li><br/>
                      <li>서비스 문의 02-3114-500</li>
                      <li>({{lv_OperTime ? lv_OperTime : ''}})</li><br/>
                      <li>보내주신 문의는 담당자 검토 후 답변드리겠습니다. 일부 문의는 휴일 이후 영업일에 답변될 수 있습니다.</li>
                    </ul>
                  </mo-tooltip>
                </span>                
              </div>
            </div>
          </mo-list-item>
        </ur-box-container>
      </ur-box-container>
    </template>
    <!-- 고정영역 //-->
    
    <!-- <ur-page-container class="msp" :show-title="false" :topButton="false" @on-scroll-bottom="fn_GetService(false)"> -->
    <!-- 컨텐츠 영역 -->
    <ur-box-container direction="column" alignV="start" class="msp-pm-wrap"> 
      <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column" class="ns-list-area msp-list-area">
        <ur-box-container v-if="totalCount > 0" alignV="start" componentid="ur_box_container_001" direction="column" class="ns-contract-list ns-accodian-mode">
          <mo-list :list-data="lv_BcResHolyQustMsgListSVO" v-if="lv_BcResHolyQustMsgListSVO.length !==0">
            <template #list-item="{item}">
              <mo-list-item v-if="item.qustTypCd === '0'" >
                <div class="list-item__contents pr0" @click="fn_OpenDetail(item)">
                  <div class="list-item__contents__title fexTy3">
                    <span class="fex-rw wauto align-item-center">
                      <span class="name maxW200 txtSkip fs20rem">{{item.qustInqryRespCntnt}}</span>                      
                    </span>                    
                    <span>
                      <mo-badge v-if="item.qustStatCd === 'Y'" class="badge-sample-badge sm blue" text="답변상태" shape="status">{{'답변완료'}}</mo-badge>                      
                    </span>
                  </div>
                  <div class="list-item__contents__info">
                    <div class="info_box">
                      <span class="crTy-blue2 con-title3">{{lv_DtlMenuObj && lv_DtlMenuObj[item.mobslSrnId] ? lv_DtlMenuObj[item.mobslSrnId] : ''}}</span>
                    </div>
                    <div class="right_box">
                      <span class="txtGray"><span class="fs14rem crTy-bk7 pr10">접수일시</span> {{item.qustAcpnNo ? $bizUtil.dateYyMmDdHhMmFormat(item.qustAcpnNo.substr(0,12)) : ''}} </span>
                    </div>
                  </div>                  
                </div>                
              </mo-list-item>
            </template>
          </mo-list>
        </ur-box-container>
        <ur-box-container v-else alignV="start" componentid="ur_box_container_001" direction="column" class="ns-contract-list no-data">
          <mo-list-item ref="expItem">
            <div class="list-item__contents">
              <div class="list-item__contents__info">
                <span class="txtGray">데이터가 없습니다.</span>
              </div>
            </div>
          </mo-list-item>
        </ur-box-container>
      </ur-box-container>
      <!-- Popup001 -->
      <mo-bottom-sheet ref="popup" close-btn class="ns-bottom-sheet closebtn title-line ">
        <template v-slot:title>
          {{lv_DtlMenuObj && lv_DtlMenuObj[lv_SelectItem.mobslSrnId] ? lv_DtlMenuObj[lv_SelectItem.mobslSrnId] : ''}}          
          <div class="mt10 sub-text">
            <span>{{userInfo.userNm}}</span><em v-if="!$bizUtil.isEmpty(userInfo.userNm)">|</em>
            <span>{{lv_SelectItem.qustAcpnNo ? $bizUtil.dateDashFormat(lv_SelectItem.qustAcpnNo.substr(0,8)) : ''}}</span>
          </div>
        </template>

        <ur-box-container ref="scrollEl" alignV="start" componentid="ur_box_container_001" direction="column" class="ns-list-area pb30 overflow-scroll-y" style="height: 400px;">
          <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column" class="ns-add-area pt20 pb0">
            <div class="crTy-bk7 fs17" v-html="$cmUtil.convertCrrgRtrnStr(lv_SelectItem.qustInqryRespCntnt || '').trim()" style="padding: 0;" />

            
            <div v-if="lv_AnswList.length !== 0">
              <div v-for="(data, index) in lv_AnswList" :key="index"> 
                <div class="mt10 sub-text pt20 pb10">
                  <span class="crTy-bk1 fs16rem fwb crTy-blue3"> {{'담당자답변' + String(lv_AnswList.length - index)}} </span>
                </div>

                <div class="crTy-bk7 fs17 w100" v-html="$cmUtil.convertCrrgRtrnStr(data.qustInqryRespCntnt || '').trim()"
                      style="padding: 10px; min-height: 100px; min-width: 300px; border: 1px solid #ddd; border-radius: 15px;" />
              </div>
            </div>            
            <div v-else>            
              <div class="mt10 sub-text pt20 pb10">
                <span class="crTy-bk1 fs16rem fwb crTy-blue3"> {{'담당자답변'}} </span>
              </div>
              <div class="crTy-bk7 fs17" v-html="$cmUtil.convertCrrgRtrnStr('없음').trim()"
                      style="padding: 10px; min-height: 100px; min-width: 300px; border: 1px solid #ddd; border-radius: 15px; width:100%" />
            </div>
            

          </ur-box-container>
        </ur-box-container>

        <ur-box-container alignV="start" componentid="" direction="column" class="ns-btn-relative-bs noshadow">
          <div class="relative-div">
            <mo-button componentid="" color="primary" shape="border" size="medium" class="ns-btn-round blue" @click="$refs.popup.close()">확인</mo-button>
            <mo-button componentid="" v-if="!isProd" color="primary" shape="border" size="medium" class="ns-btn-round blue" @click="fn_PrcsHolyDayReq()">처리(임시)</mo-button>
          </div>
        </ur-box-container
        >
      </mo-bottom-sheet>
      <!-- Popup001 end -->
    </ur-box-container>
    <!-- 컨텐츠영역  //-->
 <!-- </ur-page-container> -->

  </ur-page-container>
</template>
<script>
/***********************************************************************************
 * 공통 라이브러리 INCLUDE 영역	                                                   *
 ***********************************************************************************/
import MSPBottomAlert from '@/ui/_cmm/components/MSPBottomAlert'
import Msg from '@/systems/webkit/msg/msg'
import Screen from '~systems/mixin/screen'
import moment from 'moment'

export default {
  /***********************************************************************************
   * Vue 파일 속성 정의 영역	                                         -              *
   ***********************************************************************************/
  name: "MSPBC009M",
  screenId: "MSPBC009M",
  mixins: [Screen],
  components: {
  },
  props: {},

  /***********************************************************************************
   * Life Cycle 함수 정의 영역	                                                     *
   ***********************************************************************************/
  async created() {
    const template = '<div slot="action" class="icon-wrapper icon-wrapper-type">' +                                                
                        '<mo-button shape="border" class="ns-btn-round btn-title-style-1 mr0" @click="fn_OpenMSPBC010M">문의발송</mo-button>' +
                        '<mo-icon icon="msp-refresh-n" ref="rotateIcon" @click="fn_RotateRefresh"/>' +
                      '</div>'

    let lv_Vm = this
    this.actionSlot = {
      actionComponent: {
        template: template,
        methods: {          
          fn_OpenMSPBC010M() {
            const isFCCheck = false
            if (isFCCheck && this.getStore('userInfo').getters.getUserInfo.isFc !== 'Y') {
              const props = {
                  msg: lv_Vm.$t('pm')['EPMC021'], // "EPMC021": "FC만 이용 가능합니다."
                  closeBtn: false,
                  preventTouchClose: false,
                  closeBtn: true,
                  preventTouchClose: true
              }
              this.$cmUtil.fn_OpenAlert(this, props)
              return
            }
            
            if (!lv_Vm.lv_IsWeekDayTest && !lv_Vm.lv_IsHoliday) {
              const props = {
                  msg: '평일에는 조회만 가능합니다.<br/>IT서비스데스크(02-3114-500)를 이용해주세요',
                  closeBtn: false,
                  preventTouchClose: false,
                  closeBtn: true,
                  preventTouchClose: true
              }
              this.$cmUtil.fn_OpenAlert(this, props)
              return
            }

            this.$router.push({name: 'MSPBC010M', params: {}})
          },
          /******************************************************************************
           * Function명  : fn_RotateRefresh
           * 설명        : 새로고침 표시 로테이션
          ******************************************************************************/
          fn_RotateRefresh() {
            lv_Vm.fn_GetService(true)
            const refreshIcon = this.$refs.rotateIcon
            let degree = this.lv_lastDegree > 0 ? this.lv_lastDegree : 0

            const interval = setInterval(()=> {
              degree = (degree + 1.5) % 360 
              if (degree >= 360) {degree = 0}
              refreshIcon.style.transform = `rotate(${degree}deg)`
              this.lv_lastDegree = degree
            }, 1000 / 360)

            setTimeout(() => {              
              clearInterval(interval)
            }, 1000)
          },         
        }
      }
    }    
    this.userInfo = this.getStore('userInfo').getters.getUserInfo
    await this.fn_Init()
    this.fn_GetService(true)    
    this.fn_SelListMenuForMSP() // 메뉴 조회
    this.fn_SelListYmdInfo() // 휴일목록조회            
    this.getWkEvtHandler().$on(Msg.DEVICE.BACK_KEY, this.fn_ClickHeaderLeftClick) // backKey event 등록
  },
  mounted() {
  },
  beforeDestroy () {    
    this.getWkEvtHandler().$off(Msg.DEVICE.BACK_KEY, this.fn_ClickHeaderLeftClick) // backKey event 해제
  },
  /***********************************************************************************
   * 화면변수 선언 영역	                                                             *
   ***********************************************************************************/
  data() {
    
    return {
        lv_lastDegree: 0,
        lv_BcResHolyQustMsgListSVO: [], //요청/응답 리스트
        lv_AnswList: [], //응답 리스트      
        lv_AlertPop: '', // Alert 팝업 객체
        lv_SelectItem: {},      
        lv_DtlMenuObj: {}, // 메뉴세부항목
        userInfo: {},
        stndKeyList: [{stndKeyId: 'next_key', stndKeyVal: '0'}], //paging
        pageRowCnt: '25',
        lv_OperTime: '',
        isProd: process.env.NODE_ENV === 'production' ? true : false,
        lv_IsHoliday: false, // 휴일여부
        lv_IsWeekDayTest: false, // 주중 테스트 여부
        holidayList: [], // 휴일목록
        lv_Cnt: 1, // 임시처리 카운트
    };
  },
  watch: {    
  },
  /***********************************************************************************
   * Computed 함수 정의 영역                                                         *
   ***********************************************************************************/
  computed: {
    totalCount(){
      return this.lv_BcResHolyQustMsgListSVO.length
    },    
  },
  /***********************************************************************************
   * 사용자 함수 정의 영역                                                           *
   ***********************************************************************************/
  methods: {
    /**
     * @name fn_Init
     * @description 초기 config 설정     
     */
    async fn_Init() {            
      try {
        let srvcQustTime = await this.$commonUtil.selMgrCfg(this, {envId: 'SRVC_QUST_TIME'}) // 메뉴신규라벨목록
        if (srvcQustTime?.length) {
          this.lv_OperTime = srvcQustTime[0].smoUnitl
          console.log('srvcQustTime > ', srvcQustTime)
        }
        
        let holyQustTestPosb = await this.$commonUtil.selMgrCfg(this, {envId: 'HOLY_QUST_TEST_POSB'}) // 휴일문의테스트가능여부
        if (holyQustTestPosb?.length) {
          this.lv_IsWeekDayTest = holyQustTestPosb[0].envCntnt === 'Y' ? true : false          
          console.log('holyQustTestPosb > ', holyQustTestPosb)
        }        
      } catch (e) {
        console.log('MSPBC009M, fn_Init - error >', e)
      }
    },
    /**
     * @name fn_GetService
     * @description 휴일문의조회 호출
     * @param 요청 시 사용자사번, 휴일문의코드
     * @return 문의목록
     */
    fn_GetService(isPageInit) {
      this.userInfo = this.getStore('userInfo').getters.getUserInfo
      // if( isPageInit === true ){
      //   this.stndKeyList[0].stndKeyVal = '0'
      // }
      let params = {
        userEno: this.userInfo.userEno,
        qustTypCd: '0'
      }

      const errMsg = '재시도 후 동일 오류시 IT서비스데스크(02-3114-500)로 연락바랍니다.'      
      this.post(this, params, 'txTSSBC99S4', 'S')
        .then(response => {
          if (response?.body) {
            const data = response.body
            if (data?.qustList && data?.qustList.length > 0) {
              this.lv_BcResHolyQustMsgListSVO = [...data.qustList]
            }
            console.log('fn_GetService', data)
          } else {
            if (response.msgComm.msgCd === "EFWS004") { // 쿼리 실행 도중 오류 치환
              this.getStore('confirm').dispatch('ADD', errMsg)
            } else {
              this.getStore('confirm').dispatch('ADD', response.msgComm.msgDesc)
            }
          }

        }).catch(function (error) {          
          window.vue.error(error)
        })
    },

    /**
     * @name fn_GetAnswService
     * @description 휴일문의응답조회 호출
     * @param 요청 시 응답 조회 시 문의접수번호, 휴일문의코드
     * @return 문의응답목록
     */
    async fn_GetAnswService(pParam) {      
      let params = {
        qustAcpnNo: pParam.qustAcpnNo,
        qustTypCd: '1'
      }
      this.lv_AnswList = []
      const errMsg = '재시도 후 동일 오류시 IT서비스데스크(02-3114-500)로 연락바랍니다.'      
      await this.post(this, params, 'txTSSBC99S4', 'S')
        .then(response => {
          if (response?.body) {
            const data = response.body
            if (data?.qustList && data?.qustList.length > 0) {
              this.lv_AnswList = [...data.qustList]
              console.log('this.lv_AnswList >', this.lv_AnswList)
            }
            console.log('fn_GetAnswService', data)
          } else {
            if (response.msgComm.msgCd === "EFWS004") { // 쿼리 실행 도중 오류 치환
              this.getStore('confirm').dispatch('ADD', errMsg)
            } else {
              this.getStore('confirm').dispatch('ADD', response.msgComm.msgDesc)
            }
          }

        }).catch(function (error) {          
          window.vue.error(error)
        })
    },

    /**
     * Function명 : fn_BottomAlert
     * 설명       : 메시지를 BottomAlert으로 띄움
     * @param {String} pContent - 메시지
     * @return {none} - 반환없음
     */
    fn_BottomAlert (pContent) {
      this.lv_AlertPop = this.$bottomModal.open(MSPBottomAlert, {
        properties: {
          single: true,
          title: "알림",
          content: pContent,
          title_pos_btn: "확인"
        },
        listeners: {
          onPopupConfirm: () => {
            console.log('onPopupConfirm call')
            this.$bottomModal.close(this.lv_AlertPop)
          },
          onPopupCancel: () => {
            console.log('onPopupCancel call')
            this.$bottomModal.close(this.lv_AlertPop)
          },
          onPopupClose: () => {
            console.log('onPopupClose call')
            this.$bottomModal.close(this.lv_AlertPop)
          }
        }
      })
    },

    /******************************************************************************
    * Function명 : fn_OpenDetail
    * 설명       : 고충사항 등록내용 조회
    *
    ******************************************************************************/
    async fn_OpenDetail(item) {
      this.lv_SelectItem = item
      this.$refs.scrollEl.$el.scrollTop = 0;
      this.$refs.popup.open()
      await this.fn_GetAnswService(item)
    },

    /******************************************************************************
    * Function명  : fn_SelListMenuForMSP ()
    * 설명        : 사랑온 미니 전체메뉴 조회(권한역발별 화면 권한적용)
    * Params      : N/A
    * Return      : 사랑온 미니 전체메뉴
    ******************************************************************************/
    fn_SelListMenuForMSP () {      
      const trnstId = 'txTSSBC04SA'
      const auth = 'S'
      this.post(this, {}, trnstId, auth, {}, true).then(response => {        
        const data = response.body
        if (response?.body?.menuList?.length) {
          this.lv_DtlMenuObj = {}
          // 전체메뉴 중 화면에 노출 될 메뉴만 추출
          // 1. 관리자에서 0, 1, 2레벨의 3단계로 구성되어 있지만 화면에서는 1,2레벨로만 구성함
          // 2. 2레벨 메뉴를 가지고 있지 않은 1 레벨 메뉴들은 제외
          data.menuList.forEach(lv0 => {
            if ( lv0.menu?.length ) {
              lv0.menu.forEach(lv1 => {
                // console.log('lv1 >', lv1)
                if ( lv1.menu?.length ) {                                  
                  lv1.menu.forEach(lv2 => {
                    // console.log('lv2 >', lv2)
                    this.lv_DtlMenuObj[lv2.srnId] = lv2.label
                  })
                }
              })
            }
          })          
        }        
      })
      .catch(function (error) {
        window.vue.error(error)
      })
    },

    /*********************************************************
     * Function명: fn_PrcsHolyDayReq
     * 설명: 휴일 문의사항 처리
     *********************************************************/
    fn_PrcsHolyDayReq() {      
      console.log('fn_PrcsHolyDayReq >')
      this.userInfo = this.getStore('userInfo').getters.getUserInfo      
      const pParams = {
        qustAcpnNo: this.lv_SelectItem.qustAcpnNo,
        knoxId: 'wang.jh',
        qustInqryRespCntnt: '답변처리(임시)' + this.lv_Cnt++,
        trtRslt: 'Y'        
      }
      console.log('pParams:', pParams)
      const trnstId = 'txTSSBC99S3'
      const errMsg = '재시도 후 동일 오류시 IT서비스데스크(02-3114-500)로 연락바랍니다.'
      return this.post(this, pParams, trnstId, 'S').then(response => {        
        if (response?.body) {
          const data = response.body
          console.log('fn_PrcsHolyDayReq', data)
          let msg = ''
          if (data?.trtRslt === '0000') {
            // this.fn_GetService(true)
            this.$refs.popup.close()
            msg = '답변이 등록되었습니다.'
          } else {
            msg = errMsg + '(오류코드:' + data.trtRslt + ')'
          }
          this.getStore('confirm').dispatch('ADD', msg)
        } else {
          if (response.msgComm.msgCd === "EFWS004") { // 쿼리 실행 도중 오류 치환
            this.getStore('confirm').dispatch('ADD', errMsg)
          } else {
            this.getStore('confirm').dispatch('ADD', response.msgComm.msgDesc)
          }
        }
      }).catch(function (error) {        
        window.vue.error(error)
      })
    },

    /*********************************************************
     * Function명: fn_SetChatbotMsg
     * 설명: 챗봇 문구 setter - '챗봇 별리가 XXX님을 도와드릴게요' 라는 문구를 5초간 노출 시킴
     *                         최초 홈화면 집입 시에만 노출된다. 노출 후 해당 flag를 vuex에 저장하여
     *                         홈화면 재집입 시에는 노출시키지 않음
     * Params: N/A
     * Return: N/A
     *********************************************************/
    fn_SetChatbotMsg() {
      const t_IsCompletedChatbotMsg = this.getStore('bcStore').getters.getState.isCompletedChatbotMsg
      if ( t_IsCompletedChatbotMsg ) {
        this.lv_IsShowChatbotMsg = false
      } else {
        this.lv_IsShowChatbotMsg = true
        setTimeout(() => {
          this.lv_IsShowChatbotMsg = false
          this.getStore('bcStore').dispatch('IS_COMPLETED')
        }, 5000)
      }
    },
    /******************************************************************************
    * Function명 : fn_ChatBotContent
    * 설명       : 챗봇 링크 브라우저 호출
    *              (accessId : 접속시스템구분값(미니:Mini)/ userId : 로그인한 FC 사번)
    ******************************************************************************/
    fn_ChatBotContent () {
      console.log('fn_ChatBotContent>')
      let lv_Url = ''
      // 20220706 [ASR220600878] 사랑On 미니챗봇 스크립트 변경
      // 검증계 https://slimsg.samsunglife.com/messenger/?botId=BOT00000435&reload=N&isWeb=true&selFlag=PC
      // 운영계 https://slimsg.samsunglife.com/messenger/redirect?botId=BOT00000435&accessId=Mini&userId=사번
      if (process.env.NODE_ENV === 'local' || process.env.NODE_ENV === 'development') {
         lv_Url = 'https://ap1.t.slime2.samsunglife.kr:8449/messenger/fcBot?accessId=Mini&userId=' + this.getStore('userInfo').getters.getUserInfo.userId
      } else if (process.env.NODE_ENV === 'stage' || process.env.NODE_ENV === 'stage2') {        
        lv_Url = 'https://slimsg.samsunglife.com/messenger/redirect?botId=BOT00000435&reload=N&isWeb=true&selFlag=PC&accessId=Mini&userId=' + this.getStore('userInfo').getters.getUserInfo.userId
      } else {        
        lv_Url = 'https://slimsg.samsunglife.com/messenger/redirect?botId=BOT00000435&reload=N&isWeb=true&selFlag=PC&accessId=Mini&userId=' + this.getStore('userInfo').getters.getUserInfo.userId
      }

      if (this.$commonUtil.isMobile()) { // 모바일에서 작동
        window.fdpbridge.exec('intentBrowserPlugin', {uri: lv_Url}, () => {}, () => {})
      } else { // PC에서 작동
        window.open(lv_Url, '_blank')
      }
    },
    /******************************************************************************
    * Function명 : fn_SelListYmdInfo
    * 설명       : 공휴일 조회
    ******************************************************************************/
    fn_SelListYmdInfo () {
      let lv_Vm = this

      // 주말 검색
      if (lv_Vm.fn_CheckWeekend()) {
        lv_Vm.lv_IsHoliday = true
        return
      }

      // 공휴일 검색
      if (lv_Vm.lv_IsHoliday) return
      lv_Vm.lv_IsHoliday = true

      let pParams = {}
      let trnstId = 'txTSSAP03S2'
      lv_Vm.holidayList.splice(0, lv_Vm.holidayList.length)
      let srchStartYmd = String(moment(new Date()).format('YYYYMMDD'))      
      let srchEndYmd = srchStartYmd
      pParams =
                { 
                  inqrStrYmd: srchStartYmd, // 시작일자
                  inqrEndYmd: srchEndYmd// 종료일자
                }
      this.post(lv_Vm, pParams, trnstId, 'S')
        .then(response => {
          console.log('법정공휴일 조회 >>>>>> ', response)
          if (response.body !== null && response.body.iCYmdMngVO !== null && response.body.iCYmdMngVO.length > 0) {
            this.holidayList = response.body.iCYmdMngVO
          } else {
            // this.getStore('confirm').dispatch('ADD', response.msgComm.msgDesc)
          }
          this.lv_IsHoliday = false          
        })
        .catch(function (error) {
          window.vue.error(error)
          this.lv_IsHoliday = false
        })
    },

    /******************************************************************************
    * Function명 : fn_SelListYmdInfo
    * 설명       : 주말검색 조회
    ******************************************************************************/
    fn_CheckWeekend () {      
      const dayOfWeek = new Date().getDay()
      console.log('fn_CheckWeekend', dayOfWeek)      
      return dayOfWeek === 0 || dayOfWeek ===  6 ? true : false
    }
  }
}
</script>
<style scoped>
</style>