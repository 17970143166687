/*
 * 업무구분   : 업무공통
 * 화면 명    : MSPBC011M
 * 화면 설명  : 공지사항 등록/수정

 <template>
  <ur-page-container class="msp" type="subpage" :show-title="true" :title="setTitle" :topButton="true"><!-- type-popup -->

    <!-- Content영역 -->    
    <ur-box-container ref="scrollEl" direction="column" alignV="start" class="msp-bc-wrap overflow-scroll-y" style="height: 600px;">      
      <ur-box-container alignV="start" componentid="" direction="row" class="con-area pb132">        
        <div class="w100">
          <div class="mt50 w100">
            <span class="contain-title must circle">제목</span>
            <mo-text-field v-model.trim="lv_Title" class="form-input full mt10 input-line-black"  underline placeholder="제목을 입력하세요" />
          </div>
          <div class="mt50 w100">
            <span class="contain-title must circle">대상 구분</span>
            <ur-box-container alignV="start" componentid="" direction="row">
              <msp-bottom-select ref="bottomSelect" :items="lv_SysList" v-model="lv_SelSys" underline class="ns-dropdown-sheet full mt10" bottom-title="메뉴구분"/>
            </ur-box-container>
          </div>
        </div>
        <div class="mt50 w100">
          <span class="contain-title must circle">등록 기간</span>
          <ur-box-container alignV="start" componentid="" direction="row">
            <mo-date-picker placeholder="시작 일자" class="w110px mt0 mr80" v-model="lv_RegYmd.start" @input="fn_CheckDate()" :min-date="disabledDate.min" :max-date="disabledDate.max" />            
            <mo-date-picker placeholder="종료 일자" class="w110px mb0 flex-1" v-model="lv_RegYmd.end" @input="fn_CheckDate()" :min-date="disabledDate.min" :max-date="disabledDate.max" />
          </ur-box-container>
          
        </div>
        <div class="mt50 w100">
          <span class="contain-title must circle">PUSH 발송</span>
          <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column" class="ns-radio-list w100">
            <mo-radio v-model="lv_PushIapHyptYn" value="Y" class="mt5" >Y</mo-radio>
            <mo-radio v-model="lv_PushIapHyptYn" value="N" class="mt5" >N</mo-radio>
          </ur-box-container>
        </div>
        <span class="contain-title must circle fs18rem fwm mt50">공지내용</span>        
        <ur-box-container alignV="start" componentid="" direction="column" class="ns-segment">            
            <mo-text-area class="w150 hauto pa12 crTy-bk1" style="width:100%" cols="100" rows="10" v-model="lv_Content" placeholder="공지내용을 입력해주세요." :maxlength="500" />
        </ur-box-container>
      </ur-box-container>
    </ur-box-container>

    <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column" class="ns-btn-relative">
      <div class="relative-div">
        <mo-button componentid="mo_button_002" color="primary" shape="border" size="medium" class="ns-btn-round white" @click="fn_Close()">취소</mo-button>
        <mo-button componentid="mo_button_002" color="primary" shape="border" size="medium" class="ns-btn-round blue" @click="fn_Save()">저장</mo-button>        
      </div>
    </ur-box-container>

  </ur-page-container>
</template>
<script>
import moment from 'moment'
/***********************************************************************************
 * 공통 라이브러리 INCLUDE 영역	                                                   *
 ***********************************************************************************/
export default {
  /***********************************************************************************
   * Vue 파일 속성 정의 영역	                                                       *
   ***********************************************************************************/
  name: 'MSPBC011M',
  screenId: 'MSPBC011M',
  components: {},
  /***********************************************************************************
   * 화면변수 선언 영역	                                                             *
   ***********************************************************************************/
  props: {     
  },
  /***********************************************************************************
   * Life Cycle 함수 정의 영역	                                                     *
   ***********************************************************************************/
  created() {
    this.userInfo = this.getStore('userInfo').getters.getUserInfo
    if(this.$route.params.isModify) {
      this.lv_BltnpId = this.$route.params.pAlarmItem.iapInqrKeyVal ? this.$route.params.pAlarmItem.iapInqrKeyVal : this.$route.params.pAlarmItem.bltnpId ? this.$route.params.pAlarmItem.bltnpId : ''
      this.lv_IsModify = true
      this.fn_SelBltn()
    }    
    this.getWkEvtHandler().$on(Msg.DEVICE.BACK_KEY, this.fn_ClickHeaderLeftClick) // backKey event 등록
    
  },
  mounted() {},
  beforeDestroy () {    
    this.getWkEvtHandler().$off(Msg.DEVICE.BACK_KEY, this.fn_ClickHeaderLeftClick)
  },  
  data() {
    return {
      lv_SysList: [
        {text: '단체융자_사랑On', value: '01'},
        {text: '사랑On', value: '02'},        
        {text: '전체', value: '03'}
      ], // 시스템 목록
      lv_SelSys: '02',      
      lv_Title: '',      
      lv_RegYmd: {start: '', end: ''},
      lv_Content: '',
      lv_SnglRptId: 'TSS201810191016491470000JW81KWAK',
      lv_IsModify: false,
      lv_PushIapHyptYn: 'Y',
      lv_BltnpId: '',
      userInfo: {}
    }
  },
  /***********************************************************************************
   * Computed 함수 정의 영역                                                         *
   ***********************************************************************************/
  computed: {
    disabledDate () {
      return {
        min: this.$bizUtil.dateDashFormat(moment(new Date()).format('YYYYMMDD')),
        max: this.$bizUtil.dateDashFormat(moment(new Date()).add(3,'months').format('YYYYMMDD'))
      }
    },
    setTitle () {
      return this.lv_IsModify ? '공지사항 수정' : '공지사항 등록'
    },
  },
  /***********************************************************************************
   * watch 함수 정의 영역                                                         *
   ***********************************************************************************/
  watch: {    
  },
  /***********************************************************************************
   * 사용자 함수 정의 영역                                                           *
   ***********************************************************************************/
  methods: {
    /*******************************************************************************
     * @function fn_CheckDate
     * @name     시작일, 종료일 체크
     *******************************************************************************/
    fn_CheckDate() {      
      if (this.lv_RegYmd.start && this.lv_RegYmd.end) {
        let start = this.lv_RegYmd.start.replace(/-/gi, '')
        let end = this.lv_RegYmd.end.replace(/-/gi, '')        
        if (start > end) {
          this.getStore('toast').dispatch('ADD', '등록 시작일은 종료일 보다 클 수 없습니다.')
          return false
        }
      }
      return true
    },
    /*******************************************************************************
     * @function fn_CheckModifyDate
     * @name     수정일자 시작일, 종료일 체크
     *******************************************************************************/
    fn_CheckModifyDate(pStart, pEnd) {
      if (pStart && pEnd) {
        let start = pStart.replace(/-/gi, '')
        let end = pEnd.replace(/-/gi, '')
        let today = moment(new Date()).format('YYYYMMDD')
        if (start < today) {
          this.getStore('toast').dispatch('ADD', '시작일 ' + pStart + ', 등록 기간은 오늘 이후입니다.')          
        }

        if (end < today) {
          this.getStore('toast').dispatch('ADD', '종료일 :' + pEnd  + ', 등록 기간은 오늘 이후입니다.')          
        }
      }      
    },
    /*******************************************************************************
     * @function fn_Save
     * @name     공지사항 등록 저장
     *******************************************************************************/
    fn_Save() {      
      console.log(this.lv_Title, this.lv_SelSys, this.lv_RegYmd.start, this.lv_RegYmd.end)
      if (this.lv_Title && this.lv_SelSys && this.lv_RegYmd.start && this.lv_RegYmd.end) {
        if (this.fn_CheckDate()) {
          if (this.lv_IsModify) {
            this.fn_UdtBltn()
          } else {
            this.fn_RegBltn()
          }
        }
      } else {        
        this.getStore('toast').dispatch('ADD', '등록 정보를 확인해주세요.') 
      }
    },
    /*******************************************************************************
     * @function fn_RegBltn
     * @name     공지사항 등록
     *******************************************************************************/
    fn_RegBltn() {
      let cntByte = this.$bizUtil.getByteLength(this.lv_Content)
      if (cntByte > 4000) {
        this.getStore('confirm').dispatch('ADD', '4000자 이상을 입력할수 없습니다')
        return
      }      
      let bltnBVO = {
        snglRptId: this.lv_SnglRptId,
        bltnStrYmd: this.lv_RegYmd.start.replace(/-/gi, ''),
        bltnEndYmd: this.lv_RegYmd.end.replace(/-/gi, ''),
        bltnTitl: this.lv_Title,
        bltnCntnt: this.lv_Content,
        tbtBltnScCd: this.lv_SelSys,
        pushIapHyptYn: this.lv_PushIapHyptYn // 시스템 공지 시 푸시 여부 추가
      }
      const prcsfCd = 'I'      
      const errMsg = '재시도 후 동일 오류시 IT서비스데스크(02-3114-500)로 연락바랍니다.'
      const trnstId = 'txTSSAD04P1'
      let pParams = {'aDBltnMngDVO': [bltnBVO], 'prcsfCd': prcsfCd}
      return this.post(this, pParams, trnstId, prcsfCd).then(response => {                
        if (response.msgComm.msgCd === "EFWS026") { // 쿼리 실행 도중 오류 치환
          let msg = '공지사항이 등록되었습니다.'          
          this.getStore('confirm').dispatch('ADD', msg)
          this.fn_Close()
        } else if (response.msgComm.msgCd === "EFWS004") { // 쿼리 실행 도중 오류 치환
          this.getStore('confirm').dispatch('ADD', response.msgComm.msgDesc)
        }
      }).catch(function (error) {
        window.vue.getStore('confirm').dispatch('ADD', errMsg)
        window.vue.error(error)
      })
    },    
    /*******************************************************************************
     * @function fn_UdtBltn
     * @name     공지사항 수정
     *******************************************************************************/
    fn_UdtBltn() {
      let cntByte = this.$bizUtil.getByteLength(this.lv_Content)
      if (cntByte > 4000) {
        this.getStore('confirm').dispatch('ADD', '4000자 이상을 입력할수 없습니다')
        return
      }         
      let bltnBVO = {
        bltnpId: this.lv_BltnpId,        
        bltnStrYmd: this.lv_RegYmd.start.replace(/-/gi, ''),
        bltnEndYmd: this.lv_RegYmd.end.replace(/-/gi, ''),
        bltnTitl: this.lv_Title,
        bltnCntnt: this.lv_Content,
        tbtBltnScCd: this.lv_SelSys,
        pushIapHyptYn: this.lv_PushIapHyptYn // 시스템 공지 시 푸시 여부 추가
      }
      const prcsfCd = 'U'      
      const errMsg = '재시도 후 동일 오류시 IT서비스데스크(02-3114-500)로 연락바랍니다.'
      const trnstId = 'txTSSAD04P1'
      let pParams = {'aDBltnMngDVO': [bltnBVO], 'prcsfCd': prcsfCd}
      return this.post(this, pParams, trnstId, prcsfCd).then(response => {                
        if (response.msgComm.msgCd === "EFWS027") { // 쿼리 실행 도중 오류 치환
          let msg = '공지사항이 수정되었습니다.'          
          this.getStore('confirm').dispatch('ADD', msg)
          this.fn_Close()
        } else if (response.msgComm.msgCd === "EFWS004") { // 쿼리 실행 도중 오류 치환
          this.getStore('confirm').dispatch('ADD', response.msgComm.msgDesc)
        }
      }).catch(function (error) {
        window.vue.getStore('confirm').dispatch('ADD', errMsg)
        window.vue.error(error)
      })
    },
    /*******************************************************************************
     * @function fn_SelBltn
     * @name     공지사항 조회
     *******************************************************************************/
    fn_SelBltn() {
      const trnstId = 'txTSSAD04S3'
      let pParams = {'nextKeyBltnpId': this.lv_BltnpId}
      return this.post(this, pParams, trnstId, 'S').then(response => {                
        if (response?.body) {
          const data = response.body
          console.log('fn_SelBltn :', data)
          if(data?.aDBltnMngDVO && data.aDBltnMngDVO.length > 0) {
            const aDBltnMngDVO = data.aDBltnMngDVO[0]
            this.lv_Title = aDBltnMngDVO.bltnTitl // title
            this.lv_Content = aDBltnMngDVO.bltnCntnt // content
            this.lv_PushIapHyptYn = aDBltnMngDVO.pushIapHyptYn // pushYn
            this.lv_RegYmd.start = this.$bizUtil.dateDashFormat(aDBltnMngDVO.bltnStrYmd) // start
            this.lv_RegYmd.end = this.$bizUtil.dateDashFormat(aDBltnMngDVO.bltnEndYmd) // end    
            this.fn_CheckModifyDate(this.$bizUtil.dateDashFormat(aDBltnMngDVO.bltnStrYmd), this.$bizUtil.dateDashFormat(aDBltnMngDVO.bltnEndYmd))
          }
        }
        
      }).catch(function (error) {
        window.vue.getStore('confirm').dispatch('ADD', errMsg)
        window.vue.error(error)
      })
    },

    /************************************************************************************************
     * Function명  : fn_Close
     * 설명        : 팝업닫기
     ************************************************************************************************/
    fn_Close: function () {
      this.$router.go(-1)
    },
  }
};
</script>
<style scoped>
</style>